@import "shared/utils/responsive.module.scss";
@import "shared/utils/colors.scss";

.inputLabel {
  font-family: var(--font-neue);
  font-weight: 400;
  @include RP(
    (
      "font-size": 16,
      "line-height": 21,
    )
  );
  color: $BLACK;
  text-align: center;
}

.asterik {
  color: $SECONDARY;
}

.inputContainer {
  @include RP(
    (
      "height": 128,
      "border-radius": 8,
      "padding": 10,
    )
  );
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border: 1px solid $BORDER;
}

.inputStyle {
  width: 100%;
  height: 100%;
  border: 0px;
  outline: 0;
  font-family: var(--font-neue);
  @include RP(
    (
      "font-size": 16,
    )
  );
  resize: none;
  &::-webkit-scrollbar-track {
    border: 7px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 9999px;
    background-color: #f2f2f2;
  }

  &::-webkit-scrollbar {
    width: 20px;
  }

  &::-webkit-scrollbar-thumb {
    border: 7px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 9999px;
    background-color: $PRIMARY;
  }
}

.inputStyle::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  font-family: var(--font-poppins-400);
  color: $LIGHT_GRAY2 !important;
  opacity: 1;
  /* Firefox */
}

.inputStyle:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  font-family: var(--font-poppins-400);
  color: $LIGHT_GRAY2 !important;
}

.iinputStyle::-ms-input-placeholder {
  /* Microsoft Edge */
  font-family: var(--font-poppins-400);
  color: $LIGHT_GRAY2 !important;
}

.error {
  width: fit-content;
  color: #ea5b5b;
  font-size: 11px;
  text-align: left;
  z-index: 1;
  margin-left: 5px;
}

.lmtLabel {
  font-family: var(--font-neue);
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
  text-align: left;
  color: $GRAY;
}

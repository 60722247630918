@import "shared/utils/responsive.module.scss";
@import "shared/utils/colors.scss";

.inputLabel {
  font-family: var(--font-neue);
  font-weight: 400;
  @include RP(
    (
      "font-size": 16,
      "line-height": 21,
    )
  );
  color: $BLACK;
  text-align: center;
}

.asterik {
  color: $SECONDARY;
}

.readonly {
  background: #f4f4f4;
}

.inputContainer {
  @include RP(
    (
      "height": 56,
      "border-radius": 8,
      "padding": 10,
    )
  );
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid $BORDER;
}

.value {
  @include RP(
    (
      "font-size": 16,
    )
  );
  font-family: var(--font-poppins-400);
  color: $BLACK !important;
}

.placeholder {
  @include RP(
    (
      "font-size": 16,
    )
  );
  font-family: var(--font-poppins-400);
  color: $LIGHT_GRAY2 !important;
}

.error {
  width: fit-content;
  color: #ea5b5b;
  font-size: 11px;
  text-align: left;
  z-index: 1;
  margin-left: 5px;
}

.iconStyle {
  @include RP(
    (
      "height": 24,
      "width": 24,
    )
  );
}

.optionsContainer {
  z-index: 1;
  @include RP(
    (
      "top": 80,
      "max-height": 250,
    )
  );
  overflow-y: auto;
  &::-webkit-scrollbar-track {
    border: 7px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 9999px;
    background-color: #f2f2f2;
  }

  &::-webkit-scrollbar {
    width: 20px;
  }

  &::-webkit-scrollbar-thumb {
    border: 7px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 9999px;
    background-color: $PRIMARY;
  }
}

@import "shared/utils/responsive.module.scss";
@import "shared/utils/colors.scss";

.backDropContainer {
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  z-index: 400;
  padding: 0px !important;
  transition: visibility 0.2s;
  overflow: hidden;
  display: none;
}

.logoContainer {
  margin-bottom: 49px;
  margin-top: 20px;

  img {
    height: 70px;
    width: 70px;
    object-fit: contain;
    transition: all 0.4s ease;
    -webkit-transition: all 0.4s;
    -moz-transition: all 0.4s;
  }

  .crossContainer {
    height: 25px;
    width: 25px;
    border: 1px solid $BORDER2;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;

    svg {
      height: 20px;
      width: 20px;
    }

    &:hover {
      cursor: pointer;
    }
  }
}

.subPathsList {
  display: flex;
  flex-direction: column;
  background-color: $GRAY500;
}

.mainContainer {
  background-color: white;
  max-width: 240px;
  height: 100%;
  padding: 0px !important;
  overflow-y: auto;
  transition: transform 0.3s;
  position: absolute;
  left: 0;
  width: 100%;
  overflow-x: hidden;
  -ms-overflow-style: none !important;
  scrollbar-width: none !important;
}

.hidden {
  transform: translateX(-240px);
}

.shown {
  transform: translateX(0px);
}

.mainContainer::-webkit-scrollbar {
  display: none;
}

.crossIconContainer {
  height: 30px;
  width: 30px;
  border-radius: 8px;
  border: 1px solid $LIGHT_GRAY;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navItemContainer,
.activeNavItemContainer,
.activeSubNavItemContainer {
  display: flex;
  align-items: center;
  padding-left: 30px;
  height: 61px;
  border-left: 3px solid white;

  .icon {
    margin-right: 12px;
    min-height: 24px;
    max-height: 24px;
    min-width: 24px;
    max-width: 24px;
    height: 100%;
    width: 100%;
    filter: invert(62%) sepia(0%) saturate(4517%) hue-rotate(238deg)
      brightness(90%) contrast(83%);
  }

  .heading {
    color: $GRAY;
    font-family: var(--font-neue);
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    @include elipsis(1);
    transition: width 0.4s ease;
    -webkit-transition: width 0.4s;
    -moz-transition: width 0.4s;
  }

  &:hover {
    border-left: 3px solid $SECONDARY;
    .icon {
      filter: invert(53%) sepia(64%) saturate(1303%) hue-rotate(333deg)
        brightness(92%) contrast(87%);
    }
    .heading {
      font-weight: 500;
      color: $SECONDARY;
    }
    cursor: pointer;
  }
}

.navTitle {
  padding-left: 33px;
  color: $GRAY;
  font-family: var(--font-neue);
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  @include elipsis(1);
  transition: width 0.4s ease;
  -webkit-transition: width 0.4s;
  -moz-transition: width 0.4s;
  text-transform: uppercase;
}

.activeNavItemContainer {
  border-left: 3px solid $SECONDARY;

  .heading {
    font-weight: 500;
    color: $SECONDARY;
  }

  .icon {
    filter: invert(53%) sepia(64%) saturate(1303%) hue-rotate(333deg)
      brightness(92%) contrast(87%);
  }
}

.activeSubNavItemContainer {
  .heading {
    font-weight: 500;
    color: $SECONDARY;
  }

  .icon {
    filter: invert(53%) sepia(64%) saturate(1303%) hue-rotate(333deg)
      brightness(92%) contrast(87%);
  }
}

@media screen and (max-width: 1200px) {
  .backDropContainer {
    display: flex;
  }
}

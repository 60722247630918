@import "shared/utils/responsive.module.scss";
@import "shared/utils/colors.scss";

.bookMainHeading {
  font-size: 18px;
  text-align: left;
  color: $BLACK;
  font-weight: 500;
}

.bookMainTitle {
  font-size: 14px;
  text-align: left;
  color: $GRAY;
}

//  -------------------------------table styling
.tableMain {
  background-color: white;
  border: 1px solid #e4e7ec;
  box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1),
    0px 2px 4px -2px rgba(16, 24, 40, 0.06);
  border-radius: 8px;
}

.bookWrapper {
  align-items: center;
}

.tableContentLabel {
  font-size: 14px;
  color: black;
  height: 100%;
  vertical-align: middle;
}

.lineLoader {
  height: 10px !important;
  width: 60px !important;
}

@import "../customInput/style.module.scss";
@import "shared/utils/colors.scss";

.PhoneInputInput {
  font-family: var(--font-poppins-400);
  outline: 0px;
  border: 0px;
  background: transparent;
  color: $BLACK;
  @include RP(
    (
      "font-size": 16,
    )
  );
}

.PhoneInputCountryIcon {
  border-radius: 100% !important;
  @include RP(
    (
      "height": 24,
      "width": 24,
    )
  );
  overflow: hidden !important;
}

.PhoneInputCountryIcon--border {
  background-color: transparent !important;
  box-shadow: none !important;
}

.PhoneInputCountryIconImg {
  object-fit: cover !important;
  width: 100% !important;
  height: 100% !important;
  vertical-align: baseline !important;
}
.PhoneInputCountrySelectArrow {
  border: 0px !important;
  opacity: 1 !important;
  transform: none !important;
  width: auto !important;
  height: auto !important;
  margin-bottom: 2px;
}

.PhoneInputInput::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  font-family: var(--font-poppins-400);
  color: $LIGHT_GRAY2 !important;
  opacity: 1; /* Firefox */
}

.PhoneInputInput:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  font-family: var(--font-poppins-400);
  color: $LIGHT_GRAY2 !important;
}

.PhoneInputInput::-ms-input-placeholder {
  /* Microsoft Edge */
  font-family: var(--font-poppins-400);
  color: $LIGHT_GRAY2 !important;
}

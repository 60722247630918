@import "shared/utils/responsive.module.scss";
@import "shared/utils/colors.scss";

.inputLabel {
  font-family: var(--font-neue);
  font-weight: 400;
  @include RP(
    (
      "font-size": 16,
      "line-height": 21,
    )
  );
  color: $BLACK;
  text-align: center;
}

.asterik {
  color: $SECONDARY;
}

.readonly {
  background: #f4f4f4;
}

.inputContainer {
  @include RP(
    (
      "height": 56,
      "border-radius": 8,
      "padding": 10,
    )
  );
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid $BORDER;
}

.inputStyle {
  width: 100%;
  height: 100%;
  border: 0px;
  outline: 0;
  @include RP(
    (
      "font-size": 16,
    )
  );
  background: transparent;
}

.input-style::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  font-family: var(--font-poppins-400);
  color: $LIGHT_GRAY2 !important;
  opacity: 1;
  /* Firefox */
}

.input-style:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  font-family: var(--font-poppins-400);
  color: $LIGHT_GRAY2 !important;
}

.input-style::-ms-input-placeholder {
  /* Microsoft Edge */
  font-family: var(--font-poppins-400);
  color: $LIGHT_GRAY2 !important;
}

.error {
  width: fit-content;
  color: #ea5b5b;
  font-size: 11px;
  text-align: left;
  z-index: 1;
  margin-left: 5px;
}

.iconStyle {
  @include RP(
    (
      "height": 24,
      "width": 24,
    )
  );
}

.dateInputToggle {
  display: inline-block;
  position: relative;
  @include RP(
    (
      "height": 24,
      "width": 24,
    )
  );
}
.dateInputToggleButton {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-image: url("../../../../assets/icons/calendar.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.dateInputStyle {
  background-color: #f8f8f9;
  border: 0px;
  outline: 0;
  font-family: PoppinsMedium;
  font-size: 14px;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  box-sizing: border-box;
}
.dateInputStyle::-webkit-calendar-picker-indicator {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  cursor: pointer;
}

.dateInputTextStyle {
  border-left: 1px solid rgba(184, 181, 195, 0.2);
  height: fit-content;
  margin-left: 2px;
  padding-left: 5px;
}

$PRIMARY: #466053;
$SECONDARY: #dc6d43;
$GRAY: #868e96;
$GRAY2: #6e7c87;
$BLACK: #0f1106;
$BG: #f2f6fb;
$DARK_BLUE: #1a355c;
$LIGHT_GRAY: #f9f9f9;
$LIGHT_GRAY2: #adb5bd;
$LIGHT_GRAY3: #e4e7ec;
$BORDER: #ced4da;
$GRAY900: #160042;
$BORDER2: #e4e6f1;
$BORDER3: #e9ecef;
$GRAY500: #f5f5f6;
$BLACK800: #373737;
$SUCCESS_BG: #ecfdf3;
$SUCCESS: #027a48;
$PENDING_BG: #fffaeb;
$PENDING: #b54708;
$LIGHT_SECONDARY: #fd853a1f;
$NEUTRAL: #777e90;
$GRAYWHITE: #e5e4e2;
$GRAYTRANSPARENT: #e5e4e285;
$BLUE: #4272d7;

@import "shared/utils/responsive.module.scss";
@import "shared/utils/colors.scss";

.bookMainHeading {
  font-size: 18px;
  text-align: left;
  color: $BLACK;
  font-weight: 500;
}

.bookMainTitle {
  font-size: 14px;
  text-align: left;
  color: $GRAY;
}
.buttonMain {
  width: 140px !important;
  min-height: 44px !important;
  font-size: 16px !important;
}

.searchContainer {
  height: 44px;
  border-radius: 24px;
  border: 1px solid $GRAY;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.searchInputStyle {
  background-color: transparent;
  border: 0px;
  outline: 0px;
  margin-right: 8px;
}

.searchIconStyle {
  margin-left: 10px;
}

//  -------------------------------table styling
.tableMain {
  background-color: white;
  border: 1px solid #e4e7ec;
  box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1),
    0px 2px 4px -2px rgba(16, 24, 40, 0.06);
  border-radius: 8px;
}

.bookWrapper {
  align-items: center;
}

.tableContentLabel {
  font-size: 14px;
  color: black;
  height: 100%;
  vertical-align: middle;
}

.actionContainer {
  height: 36px;
  width: 36px;
  border-radius: 100%;
  background-color: $LIGHT_GRAY3;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    cursor: pointer;
  }
  svg {
    height: 20px;
    width: 20px;
    path {
      stroke: $GRAY;
    }
  }
}

.lineLoader {
  height: 10px !important;
  width: 60px !important;
}

.dateWrapper {
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #e4e7ec;
  box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1),
    0px 2px 4px -2px rgba(16, 24, 40, 0.06);
  border-radius: 8px;
  padding: 16px 16px;

  .inputContainer {
    @include RP(
      (
        "font-size": 16,
        "border-radius": 10,
        "max-height": 44,
      )
    );
  }
}

@import "shared/utils/responsive.module.scss";
@import "shared/utils/colors.scss";

$breakpointMedium: 1199px;

@mixin maxWidth($breakpoint) {
  @if ($breakpoint == "medium") {
    @media (max-width: $breakpointMedium) {
      @content;
    }
  }
}

.navTitle {
  padding-left: 33px;
  color: $GRAY;
  font-family: var(--font-neue);
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  @include elipsis(1);
  transition: width 0.4s ease;
  -webkit-transition: width 0.4s;
  -moz-transition: width 0.4s;
  text-transform: uppercase;
}

.navItemContainer,
.activeNavItemContainer,
.activeSubNavItemContainer {
  display: flex;
  align-items: center;
  height: 61px;
  justify-content: flex-start;
  padding-left: 30px;
  border-left: 3px solid white;

  .icon {
    margin-right: 12px;
    min-height: 24px;
    max-height: 24px;
    min-width: 24px;
    max-width: 24px;

    height: 100%;
    width: 100%;
    filter: invert(62%) sepia(0%) saturate(4517%) hue-rotate(238deg)
      brightness(90%) contrast(83%);
  }

  .heading {
    color: $GRAY;
    font-family: var(--font-neue);
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    @include elipsis(1);
    transition: width 0.4s ease;
    -webkit-transition: width 0.4s;
    -moz-transition: width 0.4s;
  }

  &:hover {
    border-left: 3px solid $SECONDARY;
    .icon {
      filter: invert(53%) sepia(64%) saturate(1303%) hue-rotate(333deg)
        brightness(92%) contrast(87%);
    }
    .heading {
      font-weight: 500;
      color: $SECONDARY;
    }
    cursor: pointer;
  }
}

.activeSubNavItemContainer {
  .heading {
    font-weight: 500;
    color: $SECONDARY;
  }

  .icon {
    filter: invert(53%) sepia(64%) saturate(1303%) hue-rotate(333deg)
      brightness(92%) contrast(87%);
  }
}

.activeNavItemContainer {
  border-left: 3px solid $SECONDARY;

  .heading {
    font-weight: 500;
    color: $SECONDARY;
  }

  .icon {
    filter: invert(53%) sepia(64%) saturate(1303%) hue-rotate(333deg)
      brightness(92%) contrast(87%);
  }
}

.sideNavFull {
  width: 256px;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
}

.logoContainer {
  @include RP(
    (
      "height": 90,
      "margin-bottom": 30,
    )
  );

  img {
    @include RP(
      (
        "height": 90,
      )
    );
    width: 90px;
    object-fit: contain;
    transition: all 0.4s ease;
    -webkit-transition: all 0.4s;
    -moz-transition: all 0.4s;
  }

  .chevIcon {
    height: 16px;
    width: 16px;

    cursor: pointer;
  }
}

.subPathsList {
  display: flex;
  flex-direction: column;
  background-color: $GRAY500;
}

@media screen and (min-width: 576px) {
  .sideNavFull {
    width: 72px;
  }
}

@media screen and (min-width: 768px) {
  .sideNavFull {
    width: 72px;
  }
}

@media screen and (min-width: 992px) {
  .sideNavFull {
    width: 72px;
  }
}

@media screen and (min-width: 1200px) {
  .sideNavFull {
    width: 256px;
  }
}

@media screen and (min-width: 1400px) {
  .sideNavFull {
    width: 256px;
  }
}

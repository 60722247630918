@import "shared/utils/responsive.module.scss";
@import "shared/utils/colors.scss";

.mainContainer {
  display: flex;
  height: 100vh;

  .assetImg {
    height: 100vh;
    object-fit: cover;
    width: 100%;
  }

  .logoContainer {
    @include RP(
      (
        "padding-top": 20,
        "padding-bottom": 20,
      )
    );
    img {
      @include RP(
        (
          "height": 195,
          "width": 195,
        )
      );
      object-fit: contain;
    }
  }

  .formContainer {
    margin-top: auto;
    margin-bottom: auto;
    @include RP(
      (
        "border-radius": 24,
        "padding": 32,
        "gap": 25,
        "max-width": 514,
      )
    );
    background-color: $GRAY500;
    width: 100%;
    display: flex;
    flex-direction: column;

    .title {
      font-family: var(--font-neue);
      font-weight: 500;
      @include RP(
        (
          "font-size": 26,
          "line-height": 31,
        )
      );
      color: $BLACK;
      text-align: center;
    }

    .subTitle {
      font-family: var(--font-neue);
      font-weight: 400;
      @include RP(
        (
          "font-size": 16,
          "line-height": 28,
        )
      );
      color: $GRAY;
      text-align: center;
    }

    .forgotLabel {
      font-family: var(--font-neue);
      font-weight: 400;
      @include RP(
        (
          "font-size": 15,
          "line-height": 16.8,
        )
      );
      color: $SECONDARY;
      text-align: right;
      &:hover {
        cursor: pointer;
      }
    }

    .bottomText {
      font-family: var(--font-neue);
      font-weight: 400;
      @include RP(
        (
          "font-size": 15,
          "line-height": 16.8,
        )
      );
      color: $GRAY;
      text-align: center;
      a {
        color: $SECONDARY;
      }
    }
  }

  &::-webkit-scrollbar {
    display: none;
  }
}

@media screen and (max-width: 1024px) {
  .mainContainer {
    .leftContainer {
      height: auto !important;
      overflow-y: unset !important;
    }
  }
}

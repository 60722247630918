@import "shared/utils/responsive.module.scss";
@import "shared/utils/colors.scss";

.topLevelContainer {
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.1);
  padding: 0 10px 0 10px;
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 200;
}

.headerContainer {
  @include RP(
    (
      "height": 70,
    )
  );
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.leftIcon {
  height: 16px;
  width: 16px;
  display: none;
  &:hover {
    cursor: pointer;
  }
}

.hamburgerIcon {
  height: 24px;
  width: 24px;
  cursor: pointer;
  display: none;
}

@media screen and (min-width: 576px) {
  .customContainer {
    max-width: 100%;
  }
}

@media screen and (max-width: 1200px) {
  .hamburgerIcon {
    display: flex;
  }
}

@import "shared/utils/responsive.module.scss";
@import "shared/utils/colors.scss";

.noContentImg {
  @include RP(
    (
      "height": 200,
      "width": 200,
    )
  );
}

.labalContainer {
  @include RP(
    (
      "max-width": 300,
    )
  );
}

.label1 {
  font-family: var(--font-neue);
  font-weight: 500;
  @include RP(
    (
      "font-size": 20,
      "line-height": 30,
    )
  );
  color: $BLACK;
  text-align: center;
}

.label2 {
  font-family: var(--font-neue);
  font-weight: 400;
  @include RP(
    (
      "font-size": 18,
      "line-height": 25,
    )
  );
  color: #667085;
  text-align: center;
}

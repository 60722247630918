@import "shared/utils/responsive.module.scss";
@import "shared/utils/colors.scss";

.contentContainer {
  @include MRP(
    (
      "max-width": 530,
      "padding-top": 15,
      "padding-bottom": 15,
    )
  );
  width: 95%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: auto;
  margin: auto;
}

@import "shared/utils/responsive.module.scss";
@import "shared/utils/colors.scss";
.contentContainer {
  width: 100%;
  background-color: white;
  height: auto;
  border-radius: 10px;
  box-shadow: 0px 0px 150px 0px #2329321a;
}

.modalBody {
  @include MRP(
    (
      "padding-top": 30,
      "padding-bottom": 30,
      "padding-left": 40,
      "padding-right": 40,
    )
  );
}

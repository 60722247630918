@import "shared/utils/responsive.module.scss";
@import "shared/utils/colors.scss";

.topLevelContainer {
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.1);
  padding: 0 10px 0 10px;
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 200;
}

.headerContainer {
  @include RP(
    (
      "height": 70,
    )
  );
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.leftIcon {
  height: 16px;
  width: 16px;
  display: none;
  &:hover {
    cursor: pointer;
  }
}

.dateWrapper {
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #e4e7ec;
  box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1),
    0px 2px 4px -2px rgba(16, 24, 40, 0.06);
  border-radius: 8px;
  padding: 16px 16px;

  .inputContainer {
    @include RP(
      (
        "font-size": 16,
        "border-radius": 10,
        "max-height": 44,
      )
    );
  }
}

@import "shared/utils/responsive.module.scss";
@import "shared/utils/colors.scss";

.modalDropContainer {
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  z-index: 999999;
  padding: 0px !important;
  transition: opacity 0.15s linear;
  overflow-x: hidden;
  overflow-y: auto;
  top: 0;
  left: 0;
  display: flex;
}

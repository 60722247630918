@import "shared/utils/colors.scss";

.tableHead {
  font-size: 12px;
  text-align: left;
  background-color: #f9fafb !important;
  color: $GRAY2 !important;
  vertical-align: middle;
  border-bottom: 0px;
  border-top: 1px solid $LIGHT_GRAY3;
  font-weight: 500 !important;
}

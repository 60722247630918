@import "shared/utils/responsive.module.scss";
@import "shared/utils/colors.scss";

.contentContainer {
  width: 100%;
  background-color: white;
  height: auto;
  border-radius: 10px;
  box-shadow: 0px 0px 150px 0px #2329321a;
}

.crossContainer {
  svg {
    @include MRP(
      (
        "height": 20,
        "width": 20,
      )
    );
    path {
      fill: $SECONDARY;
    }
    &:hover {
      cursor: pointer;
    }
  }
}

.modalBody {
  @include MRP(
    (
      "padding-bottom": 30,
      "padding-left": 40,
      "padding-right": 40,
    )
  );

  h6 {
    text-align: center;
    font-family: var(--font-neue);
    font-weight: 500;
    @include RP(
      (
        "font-size": 24,
        "line-height": 28,
      )
    );
    color: $BLACK;
  }

  p {
    text-align: center;
    font-family: var(--font-neue);
    font-weight: 400;
    @include RP(
      (
        "font-size": 16,
        "line-height": 20,
      )
    );
    color: $NEUTRAL;
  }

  .defaultButton {
    background: transparent;
    border: 1px solid $SECONDARY;
    color: $SECONDARY;
  }
}

@import "shared/utils/responsive.module.scss";
@import "shared/utils/colors.scss";

.cardContainer {
  border-left: 5px solid #eac100;
  @include RP(
    (
      "padding": 20,
    )
  );
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  flex-grow: 1;
  flex-basis: 340px;
  h1 {
    @include RP(
      (
        "font-size": 25,
      )
    );
    font-weight: 500;
    color: $BLUE;
  }
  label {
    @include RP(
      (
        "font-size": 14,
      )
    );
    text-transform: uppercase;
    font-weight: 300;
    color: $GRAY;
  }
  span {
    @include RP(
      (
        "font-size": 25,
      )
    );
    font-weight: 500;
    color: $BLUE;
    line-height: 1.2;
  }
}

.statsLoader {
  @include RP(
    (
      "height": 25,
      "width": 40,
    )
  );
}

@import "shared/utils/responsive.module.scss";
@import "shared/utils/colors.scss";

.header {
  @include MRP(
    (
      "height": 50,
    )
  );
  padding-left: 15px;
  padding-right: 15px;
  border-bottom: 1px solid $BORDER2;

  label {
    font-family: var(--font-neue);
    font-weight: 500;

    @include MRP(
      (
        "font-size": 18,
        "line-height": 21,
      )
    );
  }

  svg {
    @include MRP(
      (
        "height": 24,
        "width": 24,
      )
    );

    &:hover {
      cursor: pointer;
    }
  }

  .crossContainer {
    svg {
      @include MRP(
        (
          "height": 20,
          "width": 20,
        )
      );
      path {
        fill: $SECONDARY;
      }
      &:hover {
        cursor: pointer;
      }
    }
  }
}
